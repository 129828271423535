import {isArray} from 'lodash';
import isNil from 'lodash/isNil';
import {APPLICATIONS, DICTIONARIES_URL, EXECUTIONS, PhaseName, PROCESSES_URL, EXTERNAL_APPLICATION, PAYMENT_INTERVALS_URL} from '../constants/api-urls';
import {StringHelper} from './string-helper';

type ID = number | string;

const processUrl = (processId: ID): string => `${PROCESSES_URL}/${processId}`;
const phasesConfigurationUrl = (processId: ID, name: string): string => `${processUrl(processId)}/phases/${name}`;
const customPhasesUrl = (processId: ID): string => phasesConfigurationUrl(processId, 'custom');
const phaseActionUrl = (applicationId: ID, action: string): string => `${EXECUTIONS}/${applicationId}/${action}`;
const applicationUrl = (applicationId: ID): string => `${APPLICATIONS}/${applicationId}`;
const externalApplicationUrl = (applicationId: ID): string => `${EXTERNAL_APPLICATION}/${applicationId}`;

const constructUrlParams = (params: Record<string, unknown>): URLSearchParams => {

  const urlSearchParams = new URLSearchParams();

  Object.keys(params)
    // Filters null/undefined/blank values, as they shouldn't be part of url params.
    .filter(key => !isNil(params[key]) && !StringHelper.isBlank(String(params[key])))
    .forEach(key => {
      if (isArray(params[key])) {
        (params[key] as Array<string>).forEach(value => urlSearchParams.append(key, value.toString()));
        return;
      }
      urlSearchParams.append(key, String(params[key]));
    });

  return urlSearchParams;
};

const getUrlWithSearchParams = (url: string, params: Record<string, unknown>): string => {

  const urlSearchParams = constructUrlParams(params);

  if (!urlSearchParams.values().next().done) {
    url += `?${urlSearchParams}`;
  }

  return url;
};

export const ApiHelper = {
  constructUrlParams,
  getUrlWithSearchParams,
  getPhaseConfigurationUrl: (processId: ID, name: PhaseName): string => phasesConfigurationUrl(processId, name),
  getCustomPhasesUrl: (processId: ID): string => customPhasesUrl(processId),
  getCustomPhasesUpdateUrl: (processId: ID): string => `${customPhasesUrl(processId)}/synchronize`,
  getLoanPublishUrl: (processId: ID): string => `${processUrl(processId)}/publish`,
  getLoanValidateUrl: (processId: ID): string => `${processUrl(processId)}/validate`,
  getDictionariesUrl: (): string => DICTIONARIES_URL,
  getDictionaryUrl: (dictionaryId?: ID): string => `${DICTIONARIES_URL}/${dictionaryId}`,
  getDictionaryEntriesUrl: (dictionaryId?: ID): string => `${DICTIONARIES_URL}/${dictionaryId}/entries`,
  getDictionaryByCodeUrl: (): string => `${DICTIONARIES_URL}/entries`,
  getProcessUrl: (processId: ID, urlParams?: Record<string, unknown>): string =>
    getUrlWithSearchParams(processUrl(processId), urlParams ?? {}),
  getApplicationUrl: (applicationId: ID): string => applicationUrl(applicationId),
  getApplicationEventLogUrl: (applicationId: ID): string => `${applicationUrl(applicationId)}/logs`,
  getPhaseSaveUrl: (applicationId: ID): string => phaseActionUrl(applicationId, 'save'),
  getPhaseRevertUrl: (applicationId: ID): string => phaseActionUrl(applicationId, 'revert'),
  getPhaseMoveUrl: (applicationId: ID): string => phaseActionUrl(applicationId, 'move'),
  getPhaseValidateUrl: (applicationId: ID): string => phaseActionUrl(applicationId, 'validate'),
  getAssignApplicantUrl: (applicationId: string): string => `${applicationUrl(applicationId)}/assign-applicant`,
  getExternalApplicationUrl: (applicationId: ID): string => externalApplicationUrl(applicationId),
  getPaymentIntervalsUrl: (): string => PAYMENT_INTERVALS_URL
};
