import {Field} from '../field.model';

export interface LoanApplicationDataFormFieldsConfiguration<T_STRING_FIELD = Field, T_NUMBER_FIELD = Field<number>> {
  [key: string]: unknown;

  // Loan Parameters
  availableCreditLine: T_STRING_FIELD;
  creationType: T_STRING_FIELD;
  principal: T_NUMBER_FIELD;
  interestRate: T_NUMBER_FIELD;
  paymentIntervalId: T_NUMBER_FIELD;
  totalAmortizationNumber: T_NUMBER_FIELD;
  overrideAmortizationAmount: T_NUMBER_FIELD;
  diminishingAmortizationNumber: T_NUMBER_FIELD;
  advanceInterestNo: T_NUMBER_FIELD;
  advanceInterestApplication: T_STRING_FIELD;
  dateGranted: T_STRING_FIELD;
  firstPaymentDate: T_STRING_FIELD;
  interestCalculationParameter: T_NUMBER_FIELD;
  uidAmortizationNumber: T_NUMBER_FIELD;
  uidApplication: T_STRING_FIELD;

  // Account Information
  loanClassId: T_NUMBER_FIELD;
  loanPurposeId: T_NUMBER_FIELD;
  loanSecurityId: T_NUMBER_FIELD;
  loanEconomicActivityId: T_NUMBER_FIELD;
  microfinanceClassificationId: T_NUMBER_FIELD;
  borrowerTypeId: T_NUMBER_FIELD;
  transactionTypeId: T_NUMBER_FIELD;
  cicContractTypeId: T_NUMBER_FIELD;
  loanPurposeToIndustryId: T_NUMBER_FIELD;
  misGroupId: T_NUMBER_FIELD;

  // Co-Makers
  maxCoMakerNumber?: number,
  minCoMakerNumber?: number,
  maxCoBorrowerNumber?: number,
  minCoBorrowerNumber?: number
}

export const LOAN_APPLICATION_FIELD_CODES = {
  availableCreditLine: 'AVAILABLE_CREDIT_LINE',
  creationType: 'CREATION_TYPE',
  principal: 'PRINCIPAL',
  interestRate: 'INTEREST_RATE',
  paymentIntervalId: 'PAYMENT_INTERVAL_ID',
  totalAmortizationNumber: 'TOTAL_AMORTIZATION_NUMBER',
  overrideAmortizationAmount: 'OVERRIDE_AMORTIZATION_AMOUNT',
  diminishingAmortizationNumber: 'DIMINISHING_AMORTIZATION_NUMBER',
  advanceInterestNo: 'ADVANCE_INTEREST_NO',
  advanceInterestApplication: 'ADVANCE_INTEREST_APPLICATION',
  dateGranted: 'DATE_GRANTED',
  firstPaymentDate: 'FIRST_PAYMENT_DATE',
  interestCalculationParameter: 'INTEREST_CALCULATION_PARAMETER',
  uidAmortizationNumber: 'UID_AMORTIZATION_NUMBER',
  uidApplication: 'UID_APPLICATION',
  loanClassId: 'LOAN_CLASS',
  loanPurposeId: 'LOAN_PURPOSE',
  loanSecurityId: 'LOAN_SECURITY',
  loanEconomicActivityId: 'LOAN_ECONOMIC_ACTIVITY',
  microfinanceClassificationId: 'MICROFINANCE_CLASSIFICATION',
  borrowerTypeId: 'BORROWER_TYPE',
  transactionTypeId: 'TRANSACTION_TYPE',
  cicContractTypeId: 'CIC_CONTRACT_TYPE',
  loanPurposeToIndustryId: 'LOAN_PURPOSE_TO_INDUSTRY',
  misGroupId: 'MIS_GROUP'
};

