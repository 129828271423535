const CONFIGURATIONS = 'configurations';
const MANAGEMENT = 'management';

export const BASE_AUTH_URL = 'auth';
export const SYSTEM_URL = '/system';
export const SYSTEM_STATUS_URL = `${SYSTEM_URL}/status`;
export const SYSTEM_CONFIG_URL = `${SYSTEM_URL}/configuration`;
export const SYSTEM_SYNCHRONIZE_URL = `${SYSTEM_URL}/synchronize`;
export const SESSION_URL = `${BASE_AUTH_URL}/session`;
export const LOGIN_URL = `${BASE_AUTH_URL}/login`;
export const MFA_VERIFY_URL = `${BASE_AUTH_URL}/verify`;
export const EXECUTIONS = 'executions';
export const PROCESSES_URL = `${CONFIGURATIONS}/processes`;
export const DICTIONARIES_URL = `${CONFIGURATIONS}/dictionaries`;
export const PRODUCTS_URL = `${CONFIGURATIONS}/products`;
export const CATEGORIES_URL = `${PRODUCTS_URL}/categories`;
export const MANAGEMENT_ROLES_URL = `${MANAGEMENT}/roles`;
export const MANAGEMENT_BRANCHES_URL = `${MANAGEMENT}/branches`;
export const MANAGEMENT_USERS_URL = `${MANAGEMENT}/users`;
export const SIMULATION_URL = 'simulations';
export const LOAN_SIMULATION_URL = `${SIMULATION_URL}/standard`;
export const CALCULATE_FIRST_PAYMENT_DATE_URL = `${SIMULATION_URL}/calculate-first-payment-date`;
export const CALCULATE_TERM_URL = `${SIMULATION_URL}/calculate-term`;
export const BATCH = 'batch';
export const APPLICATIONS = 'applications';
export const INITIALIZE_APPLICATION_URL = `${APPLICATIONS}/initialize`;
export const SEARCH_APPLICANT_URL = `${APPLICATIONS}/search-applicant`;
export const AWAITING_APPROVAL_APPLICATIONS_URL = `${APPLICATIONS}/awaiting-approval`;
export const TO_UPDATE_APPLICATIONS_URL = `${APPLICATIONS}/to-update`;
export const TO_RELEASE_APPLICATIONS_URL = `${APPLICATIONS}/to-release`;
export const REASSIGN_APPLICATIONS_URL = `${APPLICATIONS}/reassign`;
export const PRE_APPROVED_TYPES_URL = `${APPLICATIONS}/pre-approved/types`;
export const DOCUMENTS_URL = 'documents';
export const PRINTS_URL = 'prints';
export const EXTERNAL_APPLICATION = 'external-application';
export const PAYMENT_INTERVALS_URL = `${PRODUCTS_URL}/payment-intervals`;

export enum PhaseName {
  AGREEMENT = 'agreement',
  CALCULATOR = 'calculator',
  CUSTOM = 'custom',
  GENERAL = 'general',
  ELIGIBILITY = 'eligibility',
  INDIVIDUAL_CUSTOMER_PROFILING = 'individual-customer-profiling',
  LOAN_APPLICATION_DATA = 'loan-application-data',
  PRE_RELEASE = 'pre-release',
  SUMMARY = 'summary'
}
