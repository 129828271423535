import {FormikErrors} from 'formik/dist/types';
import {areNotEmpty} from '../../../../../utils/empty-checks-utils';
import {LoanApplicationDataFormFields} from './loan-application-data-form.model';

export const checkAccountInformationError = (errors: FormikErrors<LoanApplicationDataFormFields>): boolean =>
  areNotEmpty([
    errors.loanClassId,
    errors.loanPurposeId,
    errors.loanSecurityId,
    errors.loanEconomicActivityId,
    errors.microfinanceClassificationId,
    errors.borrowerTypeId,
    errors.transactionTypeId,
    errors.cicContractTypeId,
    errors.loanPurposeToIndustryId,
    errors.misGroupId
  ]);

export const checkLoanParametersError = (errors: FormikErrors<LoanApplicationDataFormFields>): boolean =>
  areNotEmpty([
    errors.principal,
    errors.creationType,
    errors.availableCreditLine,
    errors.firstPaymentDate,
    errors.dateGranted,
    errors.advanceInterestApplication,
    errors.advanceInterestNo,
    errors.diminishingAmortizationNumber,
    errors.interestRate,
    errors.simulation,
    errors.totalAmortizationNumber,
    errors.fixedTotalAmortizationNumber,
    errors.paymentIntervalId
  ]);