import {useField} from 'formik';
import React, {ReactElement, useContext, useEffect, useMemo, useState} from 'react';
import {LoanParametersOptions} from '../../../../../../../shared/hooks/use-loan-application-data-options.hook';
import {DictionaryEntry} from '../../../../../../../shared/model/dictionary.model';
import {SetFieldValue} from '../../../../../../../shared/model/formik.model';
import {LoanConfiguration} from '../../../../../../../shared/model/loan-configuration.model';
import {FixedPaymentOption} from '../../../../../../../shared/model/payment.model';
import {CashHelper} from '../../../../../../../utils/cash-helper';
import {addDaysToDate} from '../../../../../../../utils/date-helper';
import {StringHelper} from '../../../../../../../utils/string-helper';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import {YES_NO_SELECT_OPTIONS} from '../../../../../../shared/form-bolean-option-values/YesNoSelectOptions';
import FormColumn from '../../../../../../shared/form-column/FormColumn';
import {FieldTrans} from '../../../calculator/Calculator';
import {CalculatorFormFields} from '../../../calculator/calculator.model';
import {LoanApplicationDataFormFields} from '../../../loan-application-data/loan-application-data-form.model';
import {ConfiguredFieldCashInput as CashInput} from '../../configured-fields/ConfiguredFieldCashInput';
import {ConfiguredFieldDatePicker as DatePicker} from '../../configured-fields/ConfiguredFieldDatePicker';
import {ConfiguredFieldNumberInput as NumberInput} from '../../configured-fields/ConfiguredFieldNumberInput';
import {
  ConfiguredFieldPercentageInput as PercentageInput
} from '../../configured-fields/ConfiguredFieldPercentageInput';
import {ConfiguredFieldSelect as Select} from '../../configured-fields/ConfiguredFieldSelect';
import SimulationParameterHint from '../../hint/SimulationParameterHint';
import {StepContext} from '../../loan-application-step/LoanApplicationStep';
import {PaymentConfiguredField} from '../../payment-configured-field/PaymentConfiguredField';
import AmortizationSchedule from '../amortization-schedule/AmortizationSchedule';
import ResetSimulationParametersButton from '../reset-simulation-parameters-button/ResetSimulationParametersButton';
import SimulationResultParameters from '../simulation-parameters/SimulationParameters';
import {
  SimulationParametersChangedError,
  SimulationRequiredError
} from '../simulation-parameters/SimulationParametersError';
import StartSimulationButton from '../start-simulation-button/StartSimulationButton';
import styles from './LoanParameters.module.scss';
import {useCalculateFirstPaymentDate} from './use-calculate-first-payment-date';
import {useCalculateTerm} from './use-calculate-term';

const COMMON_TRANS_PATH = 'COMMON';
const PrefixTrans = TransHelper.getPrefixedTrans(COMMON_TRANS_PATH + '.FIELDS');

interface Props {
  values: CalculatorFormFields | LoanApplicationDataFormFields;
  loanParametersOptions: LoanParametersOptions;
  fixedPaymentOptions?: FixedPaymentOption[];
  submitSimulation: () => void;
  isSubmitting: boolean;
  simulationParamsChanged?: boolean;
  setFieldValue: SetFieldValue;
  loanConfig: LoanConfiguration;
  resetSimulationParameters: () => void;
  setMinFirstPaymentDate: (string) => void;
}

export default function LoanParameters(
  {
    values,
    loanParametersOptions,
    submitSimulation,
    fixedPaymentOptions,
    isSubmitting,
    simulationParamsChanged,
    setFieldValue,
    loanConfig,
    resetSimulationParameters,
    setMinFirstPaymentDate
  }: Props
): ReactElement {

  const {isStepReadonly} = useContext(StepContext);
  const {simulation} = values;
  const [calculatedFirstPaymentDate, setCalculatedFirstPaymentDate] = useState();

  const [, meta] = useField('simulation');

  const maxFirstPaymentDate = useMemo(
    () =>
      addDaysToDate(
        calculatedFirstPaymentDate,
        loanConfig.maxFirstPaymentPostponement
      ),
    [calculatedFirstPaymentDate, values.dateGranted.value, loanConfig.maxFirstPaymentPostponement]
  );

  const minFirstPaymentDate = useMemo(
    () => {
      if (calculatedFirstPaymentDate === values.dateGranted.value) {
        return addDaysToDate(
          calculatedFirstPaymentDate,
          1
        );
      }
      return calculatedFirstPaymentDate;
    },
    [calculatedFirstPaymentDate, values.dateGranted.value, loanConfig.maxFirstPaymentPostponement]
  );

  const onFirstPaymentDateCalculated = (firstPaymentDate): void => {
    setFieldValue('firstPaymentDate', firstPaymentDate);
    setCalculatedFirstPaymentDate(firstPaymentDate.value);
  };

  useEffect(() => {
    if (minFirstPaymentDate) {
      setMinFirstPaymentDate(minFirstPaymentDate);
    }
  }, [minFirstPaymentDate]);

  useCalculateFirstPaymentDate(values, onFirstPaymentDateCalculated);
  useCalculateTerm(values, setFieldValue);

  const interestRateHint = <SimulationParameterHint fieldName={'INTEREST_RATE'}
                                                    min={StringHelper.addPercentage(loanConfig.minInterestRate)}
                                                    max={StringHelper.addPercentage(loanConfig.maxInterestRate)} />;

  const principalAmountHint = <SimulationParameterHint fieldName={'PRINCIPAL'}
                                                       min={CashHelper.addCurrencyMask(loanConfig.minAmount)}
                                                       max={CashHelper.addCurrencyMask(loanConfig.maxAmount)} />;

  return (
    <div className={styles.container}>
      <FormColumn header={<PrefixTrans>CALCULATION_DATA</PrefixTrans>}>
        <Select name='availableCreditLine'
                field={values.availableCreditLine}
                label={<PrefixTrans>AVAILABLE_CREDIT_LINE</PrefixTrans>}
                options={YES_NO_SELECT_OPTIONS} />
        <Select name='creationType'
                field={values.creationType}
                label={<PrefixTrans>CREATION_TYPE</PrefixTrans>}
                options={loanParametersOptions.creationType} />
        <CashInput name='principal'
                   field={values.principal}
                   label={<FieldTrans>PRINCIPAL</FieldTrans>}
                   hint={principalAmountHint}
        />
        <PercentageInput name='interestRate'
                         field={values.interestRate}
                         label={<FieldTrans>INTEREST_RATE</FieldTrans>}
                         hint={interestRateHint} />
        <PaymentConfiguredField values={values}
                                setFieldValue={setFieldValue}
                                fixedOptions={fixedPaymentOptions}
                                paymentIntervalOptionsProps={loanParametersOptions.paymentIntervalOptionsProps} />
        {
          !!values.overrideAmortizationAmount &&
          <NumberInput name='overrideAmortizationAmount'
                       field={values.overrideAmortizationAmount}
                       label={<PrefixTrans>OVERRIDE_AMORTIZATION_AMOUNT</PrefixTrans>}
                       min={0} />
        }
        {
          !!values.diminishingAmortizationNumber &&
          <NumberInput name='diminishingAmortizationNumber'
                       field={values.diminishingAmortizationNumber}
                       label={<PrefixTrans>DIMINISHING_AMORTIZATION_NUMBER</PrefixTrans>}
                       min={0} />
        }
        <NumberInput name='advanceInterestNo'
                     field={values.advanceInterestNo}
                     label={<PrefixTrans>ADVANCE_INTEREST_NO</PrefixTrans>}
                     min={0} />
        <Select name='advanceInterestApplication'
                field={values.advanceInterestApplication}
                label={<PrefixTrans>ADVANCE_INTEREST_APPLICATION</PrefixTrans>}
                options={loanParametersOptions.advanceInterestApplication} />
        {
          !!values.interestCalculationParameter &&
          <NumberInput name='interestCalculationParameter'
                       field={values.interestCalculationParameter}
                       label={<FieldTrans>INTEREST_CALCULATION_PARAMETER</FieldTrans>}
                       min={0} />
        }
        {
          !!values.uidAmortizationNumber &&
          <NumberInput name='uidAmortizationNumber'
                       field={values.uidAmortizationNumber}
                       label={<FieldTrans>UID_AMORTIZATION_NO</FieldTrans>}
                       min={0} />
        }
        {
          !!values.uidApplication &&
          <Select name='uidApplication'
                  field={values.uidApplication}
                  label={<FieldTrans>UID_APPLICATION</FieldTrans>}
                  options={loanParametersOptions.uidApplicationOptions} />
        }
        <DatePicker name='dateGranted'
                    label={<PrefixTrans>DATE_GRANTED</PrefixTrans>}
                    field={values.dateGranted} />
        <DatePicker name='firstPaymentDate'
                    label={<PrefixTrans>FIRST_PAYMENT_DATE</PrefixTrans>}
                    field={values.firstPaymentDate}
                    maxDate={maxFirstPaymentDate}
                    minDate={minFirstPaymentDate} />
        {
          !isStepReadonly && <StartSimulationButton onSubmit={submitSimulation} isSubmitting={isSubmitting} />
        }
        {
          simulation && !isStepReadonly &&
          <ResetSimulationParametersButton onSubmit={resetSimulationParameters} isSubmitting={isSubmitting} />
        }
        {
          !simulation && meta.error && <SimulationRequiredError />
        }
        {
          simulationParamsChanged && <SimulationParametersChangedError />
        }
      </FormColumn>
      <SimulationResultParameters simulation={simulation?.result} />
      {
        simulation?.result && <AmortizationSchedule simulation={simulation.result} />
      }
    </div>
  );
}
