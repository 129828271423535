import {NxTabs} from '@nextbank/ui-components';
import {Form, FormikProps} from 'formik';
import {isEqual} from 'lodash';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {
  AccountInformationOptions,
  LoanParametersOptions
} from '../../../../../shared/hooks/use-loan-application-data-options.hook';
import {PostRequest} from '../../../../../shared/hooks/use-post.hook';
import {DictionaryEntry} from '../../../../../shared/model/dictionary.model';
import {LoanSimulation, LoanSimulationParams} from '../../../../../shared/model/loan-simulation.model';
import {PrintType} from '../../../../../shared/model/print.model';
import {
  validateAndSubmitForm,
  VALIDATION_INDICATOR_FIELD
} from '../../../../../utils/step-form-utils/validation-schema/validation-schema-utils';
import {
  LoanApplicationDataPhase
} from '../../../../loan-configurations/loan-configuration/steps/loan-application-data/loan-application-data-phase.model';
import {LoanApplicationContext} from '../../LoanApplication';
import LoanApplicationStep from '../shared/loan-application-step/LoanApplicationStep';
import AccountInformation from '../shared/simulation/account-information/AccountInformation';
import {FeesOverride} from '../shared/simulation/fees-override/FeesOverride';
import LoanParameters from '../shared/simulation/loan-parameters/LoanParameters';
import {checkAccountInformationError, checkLoanParametersError} from './loan-application-data-form-utils';
import {
  LoanApplicationDataFormFields,
  SIMULATION_PARAMS_CHANGED_INDICATOR_FIELD,
  SIMULATION_REQUIRED_INDICATOR_FIELD
} from './loan-application-data-form.model';
import {PrefixTrans} from './LoanApplicationData';
import {sortSimulatedFees} from '../../../../../utils/fees-override-helper';

interface Props {
  config: LoanApplicationDataPhase;
  formikProps: FormikProps<LoanApplicationDataFormFields>;
  runSimulation: PostRequest<LoanSimulation[], LoanSimulationParams>;
  getSimulationDataValues: (fields: LoanApplicationDataFormFields) => LoanSimulationParams;
  showErrorMessage: (message: string) => void;
  options: {
    accountInformationOptions: AccountInformationOptions;
    loanParametersOptions: LoanParametersOptions;
  };
  setMinFirstPaymentDate: (string) => void;
}

export const LoanApplicationDataForm = (
  {
    formikProps,
    config,
    runSimulation,
    getSimulationDataValues,
    options,
    showErrorMessage,
    setMinFirstPaymentDate
  }: Props
): ReactElement => {

  const {t} = useTranslation();
  const {process, application} = useContext(LoanApplicationContext);
  const loanConfig = process.loanProduct;
  const {values, dirty, errors, setFieldValue, submitForm, validateForm, isSubmitting, setSubmitting} = formikProps;
  const simulatedFees = values.simulation?.input.simulatedFees;

  const hasBorrowerSourceId = !!application?.borrower?.sourceId;
  const hasBorrowerBirthDate = !!application?.borrower?.individualData?.birthDate

  const {
    loanClassId, loanPurposeId, loanSecurityId, loanEconomicActivityId, microfinanceClassificationId, misGroupId,
    borrowerTypeId, transactionTypeId, cicContractTypeId, loanPurposeToIndustryId
  } = mapValues(values, 'value');

  const simulationInput = {
    ...getSimulationDataValues(values),
    simulatedFees,
    accountInformation: {
      loanClassId, loanPurposeId, loanSecurityId, loanEconomicActivityId, microfinanceClassificationId, misGroupId,
      borrowerTypeId, transactionTypeId, cicContractTypeId, loanPurposeToIndustryId
    },
    ...(hasBorrowerSourceId && { customerId: application?.borrower?.sourceId }),
    ...(!hasBorrowerSourceId && hasBorrowerBirthDate && { birthDate: application?.borrower?.individualData?.birthDate })
  };

  const submitSimulation = async (): Promise<void> => {

    const validate = true;
    const simulationParamsChanged = false;
    setFieldValue(VALIDATION_INDICATOR_FIELD, validate);
    setFieldValue(SIMULATION_REQUIRED_INDICATOR_FIELD, simulationParamsChanged);

    const errors = await validateForm({...values, validate, simulationParamsChanged});

    if (!isEmpty(errors)) {
      return;
    }

    const setSimulation = (result?: LoanSimulation, input?: LoanSimulationParams): void =>
      setFieldValue('simulation', result && input ? getUpdatedSimulationFromData(result, input) : undefined);

    const getUpdatedSimulationFromData = (result: LoanSimulation,
                                          input: LoanSimulationParams): {
      result: LoanSimulation,
      input: LoanSimulationParams
    } => {
      return {
        result: result,
        input: {
          ...input,
          simulatedFees: result.simulatedFees
        }
      };
    };

    setSubmitting(true);
    runSimulation(simulationInput)
      .then(simulations => {
        if (!isEmpty(simulations) && !isNil(simulations)) {
          setSimulation(simulations[0], simulationInput);
        }
      })
      .catch(error => {
        setSimulation(undefined);
        showErrorMessage(error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const resetSimulationParameters = (): void => {
    setFieldValue('simulation', undefined);
  };

  const simulationParamsChanged = values.simulation?.result && !isEqual(values.simulation.input, simulationInput);

  const loanParametersTab = {
    key: 'LOAN_PARAMETERS',
    error: simulationParamsChanged || checkLoanParametersError(errors),
    label: <PrefixTrans>LOAN_PARAMETERS</PrefixTrans>,
    tabPanel: <LoanParameters fixedPaymentOptions={config?.fixedPaymentOptions}
                              loanParametersOptions={options.loanParametersOptions}
                              simulationParamsChanged={dirty && simulationParamsChanged}
                              submitSimulation={submitSimulation}
                              resetSimulationParameters={resetSimulationParameters}
                              setFieldValue={setFieldValue}
                              isSubmitting={isSubmitting}
                              values={values}
                              loanConfig={loanConfig}
                              setMinFirstPaymentDate={setMinFirstPaymentDate} />
  };

  const accountInformationTab = {
    key: 'ACCOUNT_INFORMATION',
    error: checkAccountInformationError(errors),
    label: <PrefixTrans>ACCOUNT_INFORMATION.LABEL</PrefixTrans>,
    tabPanel: <AccountInformation values={values} options={options.accountInformationOptions} />
  };

  const feesOverrideTab = {
    key: 'FEES_OVERRIDE',
    label: <PrefixTrans>FEES_OVERRIDE.LABEL</PrefixTrans>,
    tabPanel: <FeesOverride fees={simulatedFees} />
  };

  const handleSave = async (validate: boolean): Promise<void> =>
    validateAndSubmitForm<LoanApplicationDataFormFields>(
      {
        ...values,
        [SIMULATION_REQUIRED_INDICATOR_FIELD]: true,
        [SIMULATION_PARAMS_CHANGED_INDICATOR_FIELD]: simulationParamsChanged
      }, validate, submitForm, validateForm, setFieldValue, t);

  useEffect(() => {
    if (simulatedFees !== undefined) {
      sortSimulatedFees(simulatedFees);
    }
  }, [simulatedFees]);

  return (
    <LoanApplicationStep handleSave={handleSave}
                         phaseId={config?.id}
                         dataChanged={dirty}
                         approvalRules={config?.approvalRules}
                         printType={PrintType.LOAN_APPLICATION_DATA}>
      <Form>
        <NxTabs tabs={[loanParametersTab, accountInformationTab, feesOverrideTab]} />
      </Form>
    </LoanApplicationStep>
  );
};

