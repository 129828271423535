import {NxSelectOption} from '@nextbank/ui-components';
import {isNil, orderBy} from 'lodash';
import {Category} from '../shared/model/category.model';
import {Dictionary, DictionaryEntry} from '../shared/model/dictionary.model';
import {addParentsToCategoryName} from './category-utils';
import {DictionaryHelper} from './dictionary-helper';
import {PaymentInterval} from '../shared/model/payment-interval.model';

interface Entry {
  id: number;
  name: string;
  enabled?: boolean;
  orderNo?: number;
}

const mapToStringOptions = (entries: Entry[] = []): NxSelectOption[] =>
  mapToNumberOptions(entries).map(option => ({...option, value: String(option.value)}));

const mapPaymentIntervalsToStringOptions = (paymentIntervals: PaymentInterval[] = []): NxSelectOption[] =>
  mapPaymentIntervalsToNumberOptions(paymentIntervals).map(option => ({...option, value: String(option.value)}));

const mapPaymentIntervalsToNumberOptions = (paymentIntervals: PaymentInterval[] = []): NxSelectOption<number>[] =>
  paymentIntervals
    .sort(((paymentIntervalA, paymentIntervalB) => (paymentIntervalA.name < paymentIntervalB.name ? -1 : 1)))
    .map(paymentInterval => ({value: paymentInterval.id, label: paymentInterval.name, disabled: !isNil(paymentInterval.active) && !paymentInterval.active}));

const mapToNumberOptions = (entries: Entry[] = []): NxSelectOption<number>[] =>
  entries
    .sort((entryA, entryB) => (entryA.name < entryB.name ? -1 : 1))
    .sort((entryA, entryB) => !isNil(entryA?.orderNo) && !isNil(entryB?.orderNo) && entryA.enabled && entryB.enabled
       ? (entryA?.orderNo < entryB?.orderNo ? -1 : 1)
       : 0
    )
    .map((entry) => ({ value: entry.id, label: entry.name, disabled: !isNil(entry.enabled) && !entry.enabled }));

const toLabelArray = (entries: Entry[] = []): string[] =>
  entries.map(entry => entry.name);

export const SelectHelper = {

  mapToStringOptions,

  mapToNumberOptions,

  mapPaymentIntervalsToStringOptions,

  mapPaymentIntervalsToNumberOptions,

  toLabelArray,

  mapDictionaryToOptions: (dictionaries?: Dictionary[], dictionaryId?: number): NxSelectOption[] | undefined => {
    if (!dictionaries || !dictionaryId) {
      return;
    }

    const entries = DictionaryHelper.getEntries(dictionaries, dictionaryId);

    return mapToStringOptions(entries);
  },

  mapCategoriesToOptions: (categories: Category[]): NxSelectOption<number>[] => {

    const categoriesWithParentNames = addParentsToCategoryName(categories);

    // TODO sort categories on FE by orderNo not names
    return mapToNumberOptions(orderBy(categoriesWithParentNames, ['name']) as DictionaryEntry[]);
  }
};

